@tailwind base;
@tailwind components;
@tailwind utilities;

.minHeight100vh {
  min-height: calc(var(--vh, 1vh) * 100);
}

.maxHeight100vh {
  max-height: calc(var(--vh, 1vh) * 100);
}

.height100vh {
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
}

/* Headline */
@font-face {
  font-family: "KoenigBauerH";
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerHeadline/KoenigBauerH-Light.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerH";
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerHeadline/KoenigBauerH-Book.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerH";
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerHeadline/KoenigBauerH-Medium.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerH";
  font-weight: 600;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerHeadline/KoenigBauerH-SemiBold.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerH";
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerHeadline/KoenigBauerH-Bold.woff) format("woff");
}

/* Text */
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 100;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-Hairline.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 200;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-ExtraLight.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-Light.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-Book.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-Medium.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 600;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-SemiBold.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-Bold.woff) format("woff");
}
